@media screen and (min-height: 200px) and (max-height: 400px) and (max-width: 810px) {

    .wrapperM, .whatsappWeb, .help {
        display: none;
    }
    .wrapper {
        width: 100vw;
        display: block;
        box-sizing: border-box;
    }
    .whatsappMobile {
        display: block;
    }
    .header {
        height: 70vh;
        width: 100vw;
        box-sizing: border-box;
        background-size: cover;
        text-align: center;
        &__phonenumber {
            font-size: 110%;
            top: 4vh;
            left: 77vw;
        }
        &__whatsApp {
            top: -1.5vh;
            left: 1vw;
        }
        &__title {
            top: 11vh;
            right: 10vw;
            font-size: 230%;
            font-weight: 200;
        }
        &__buttons {
            width: auto;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
            top: 25vh;
            &_size {
                text-decoration: none;
                display: inline-block;
                width: 35vw;
                height: 50px;
                line-height: 15px;
                border: none;
                border-radius: 15px;
                margin: 10px 10px;
                font-family: 'Noto Serif Regular';
                font-size: 12px;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 3px;
                font-weight: 900;
                color: #000000;
                background: #FF8000;
                box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
                transition: .3s;
                opacity: 0.8;
            }
            &_size:hover {
                background: #2EE59D;
                box-shadow: 0 15px 20px rgba(46, 229, 157, 0.4);
                color: white;
                transform: translateY(-7px);
            }
        }
    }
    .advantages {
        height: auto;
        top: 75vh;
        display: block;
        &__box {
            width: 90%;
            font-family: 'Noto Serif Regular';
            background: rgb(229, 241, 248);
            opacity: 0.9;
            border-radius: 30px;
            padding: 0.7%;
            box-sizing: border-box;
            margin-top: 3vh;
            margin-left: 5%;
            }
        &__box:hover{
            background: radial-gradient(ellipse at bottom, #87e0fd 0%,#ffffff 55%);
            opacity: 2;
            }    
        & h2 {
            font-size: 17px;
            font-weight: 500;
            line-height: 15px;
            color: red;
        }
        & p {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
        }
    }
    .title {
        margin-top: 115vh;
        margin-bottom: 4vh;
        font-size: 120%;

    }
    .helpM {
        width: 100vw;
        box-sizing: border-box;
        display: block;
        margin-bottom: 4vh;
        padding-left: 10vw; 
        &__item {
            background-color: #ffff;
            width: 75vw;
            border-radius: 20px;
            margin-bottom: 2vh;
            padding: 15px;
            line-height: 4vh;
            &_box {
                display: flex;
                justify-content: left;
                font-size: 80%;
            }
            &_img1 {
                width: 110px;
                height: 110px;
                margin-left: 1vw;
                background-image: url(../img/ручка.jpg);
                background-size: cover;
            }
            &_img2 {
                width: 110px;
                height: 110px;
                margin-left: 1vw;
                background-image: url(../img/весы.png);
                background-size: cover;
            }
            &_img3 {
                width: 110px;
                height: 75px;
                background-size: cover;
                margin-left: -4vw;
                background-image: url(../img/молот.jpg);
            }
            &_img4 {
                width: 200px;
                height: 150px;
                background-size: cover;
                margin-left: 10px;
                background-image: url(../img/книга.png);
            }
            &_img5 {
                width: 100px;
                height: 75px;
                background-size: cover;
                margin-left: 10px;
                background-image: url(../img/экспертиза.jpg);
            }
            &_img6 {
                width: 300px;
                height: 100px;
                background-size: cover;
                margin-left: 70px;
                background-image: url(../img/регистрация.jpg);
            }
        }        
        &__item:hover{
            color: blue;
            border: 2px solid red;
        }
        & span {
            font-size: 120%;
        }
        & li {
            width: 50vw;
            margin-top: 1vh;
            font-weight: 500;
            list-style-type:decimal;
            list-style-position:inside;
            font-style: unit($number: 1);
        }
    }

    .form {
        width: 60vw;
        padding: 5vh;
    & h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        }
    &__input {
        display: block;
        font-size: 100%;
        width: 50vw;
        height: 12vh;
        }
    &__sendButton {
        margin-top: 2vh;
        margin-left: 8vw;
        }
    &__mesage {
        display: block;
        font-size: 140%;
        width: 50vw;
        height: 35vh;
        }
    }  
    .lastButton {
        display: block;
        height: 30vh;
    }
    .footer {
        width: 100vw;
        height: 40vh;
        box-sizing: border-box;
        padding: 1px;
        background-color: #000080;
        line-height: 16px;
        font-size: 14px;
        justify-content: space-between;
        &-box {
            line-height: 25px;
            &__contacts {
                &_number {
                    margin-left: 4.5vw;
                }
            }
        }
        &__ava {
            width: 35vw;
            justify-content: space-between;
        }
        &__links {
            display: none;
        }
        &__buttonAp13 {
            display: none;
        }
        &__buttonAp12 {
            display: block;
            color: white;
            width: 25vw;
            height: 15vh;
            padding-left: 20px;
            font-size: 20px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
    }
    .fourBusines {
        height: auto;
        &__buttonBack {
            width: 17vw;
            height: 10vh;
        }
        &__header {
            font-size: 24px;
            padding-left: 25vw;
            font-weight: 500;
            color: black;
            animation: blinker 1s linear infinite;
            margin-bottom: 32px;
        }
        &__phonenumber {
            font-size: 110%;
            top: 5vh;
            left: 70vw;
            font-weight: 500;
            color: black;
            animation: blinker 1s linear infinite;
        }
        @keyframes blinker {
            0% {
                color: orange;
            }
            33% {
                color: red;
            }
            66% {
                color: rgb(7, 0, 15);
            }
            100% {
                color: orange;
            }
        }
        &__WhatsApp2 {
            left: 88vw;
            top: 14vh;
        }
        &__sendtomail {
            height:65px;
            width:65px;        
            position: fixed;
            top: 50vh;
            left: 88.5vw;
        }
        &-boxitem {
        height: 170vh;
            &__advantages {
                font-family: 'Noto Serif Regular';
                font-size: 25px;
                font-weight: 900;
                color: black;
                padding-top: 7px;
                margin: auto;
                &_mod {
                    width: 37vw;
                    height: 42vh;
                    span {
                        display: block;
                        margin-top: 1vh;
                        font-size: 14px;
                        font-weight: 500;
                        color: black;
                    }
                }
            }
        }
    }
}