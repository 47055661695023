@media screen and (max-width: 330px) and (max-height: 570px) {
    .wrapper, .whatsappWeb {
        display: none;
    }
    .whatsappMobile {
        display: block;
    }
    .wrapperM {
        width: 100vw;
        display: block;
        box-sizing: border-box;        
        font-family: 'Noto Serif Regular';
        background: rgb(200, 242, 253);
        text-align: center;
    }
    .headerM {
        height: 270px;
        background:url(../img/879.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        &__phonenumber {
            font-size: 15px;
            font-weight: 500;
            color:#0c08f5;
            position: absolute;
            top: 14vh;
            left: 57vw;
        }
        &__whatsApp {
            position: fixed;
            z-index: +2;
            top: 5vh;
            left: 2vw;
        }
        &__title {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            top: 0.7vh;
            font-size: 170%;
            font-style: normal;
            font-weight: 500;
            color: rgb(4, 4, 66);
            line-height: 20px;
            animation: blinker 1s linear infinite;
            text-shadow: 0px 3px 0px #b2a98f,
                 0px 14px 10px rgba(0,0,0,0.15),
                 0px 24px 2px rgba(0,0,0,0.1),
                 0px 34px 30px rgba(0,0,0,0.1);
            }
        @keyframes blinker {
            0% {
                color: orange;
            }
            33% {
                color: red;
            }
            66% {
                color: blue;
            }
            100% {
                color: orange;
            }
        }
        &__buttons {
            width: auto;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
            top: 25vh;
            &_size {
                text-decoration: none;
                display: inline-block;
                width: 40vw;
                height: 50px;
                line-height: 15px;
                border: none;
                border-radius: 15px;
                margin: 10px 10px;
                font-family: 'Noto Serif Regular';
                font-size: 12px;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 1px;
                font-weight: 900;
                color: #000000;
                background: #FF8000;
                box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
                transition: .3s;
                opacity: 0.8;
            }
            &_size:hover {
                background: #2EE59D;
                box-shadow: 0 15px 20px rgba(46, 229, 157, 0.4);
                color: white;
                transform: translateY(-7px);
            }
        }
    }
    .advantagesM {
        height: auto;
        top: 1vh;
        &__box {
            width: 90%;
            font-family: 'Noto Serif Regular';
            background: rgb(229, 241, 248);
            opacity: 0.9;
            border-radius: 30px;
            padding: 0.7%;
            box-sizing: border-box;
            margin-top: 3vh;
            margin-left: auto;
            margin-right: auto;
            }
        &__box:hover{
            background: radial-gradient(ellipse at bottom, #87e0fd 0%,#ffffff 55%);
            opacity: 2;
            }    
        & h2 {
            font-size: 17px;
            font-weight: 500;
            line-height: 15px;
            color: red;
        }
        & p {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            padding: 3px;
        }
    }
    .title {
        margin-top: 2vh;
        margin-bottom: 2vh;
        font-size: 17px;
    }
    .help {
        width: 90%;
        display: block;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        &__item {
            border-radius: 20px;
            padding: 20px;
            line-height: 16px;
            &_box {
                display: block;
            }
            &_img1 {
                width: 70vw;
                height: 150px;
                margin-left: 2px;
                margin-top: 15px;
            }
            &_img5 {
                width: 70vw;
                height: 150px;
                background-size: cover;
                margin-top: 5px;
                margin-left: 4px;
                background-image: url(../img/экспертиза.jpg);
            }
            &_img6 {
                width: 70vw;
                height: 125px;
                background-size: cover;
                margin-top: 5px;
                margin-left: 3px;
                background-image: url(../img/регистрация.jpg);
            }
        }
        & span {
            font-size: 16px;
            font-weight: 500;
        }
        & li {
            margin-top: 5px;
            font-weight: 500;
        }
    }
    .form {
        width: 90vw;
        padding: 5%;
        &__error {
            display: none;
            color: red;
            font-size: 14px;
            font-weight: 900;
        }
        &-header {
            text-align: center;
            margin-bottom: 15px;
            font-size: 12px;
            &__info {
                text-align: center;
                font-size: 90%;
                line-height: 16px;
                color:blue;
            }
        }
    & h3 {
        font-size: 15px;
        }
    &__input {
        font-size: 100%;
        width: 75vw;
        height: 7vh;
        }
    &__mesage {
        font-size: 18px;
        width: 75vw;
        height: 25vh;
        }    
    }
    .check19-10 {
        display: block;
    }

    .footerM {
        width: 100vw;
        height: 55vh;
        box-sizing: border-box;
        background-color: #000080;
        color: #ffff;
        margin-top: 40px;
        padding: 15px;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 35px;
        .footerM-box {
            width: 90vw;
            margin-top: 15px;
            display: flex;
            justify-content: space-around;
            &-towBlock {
                line-height: 24px;
                &__contacts {
                    color: white;
                    text-decoration: none;
                    animation: blinker 1s linear infinite;
                    &_number {
                        margin-left: 1.8vw;
                    }
                }
            }
        }
        @keyframes blinker {
            0% {
                color: orange;
            }
            33% {
                color: red;
            }
            66% {
                color: white;
            }
            100% {
                color: orange;
            }
        }
        &-links {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            &__link {
                text-decoration: none;
                color: white;
                animation: blinker 1s linear infinite;
            }
        }
    }

    .lastButton {
        &__buttonAp {
            color: white;
            width: 50vw;
            height: 7.5vh;
            margin: auto;
            padding-left: 20px;
            font-size: 20px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
        &__buttonAp:hover {
            background-color: #00bcd4;
        }
    }
    .top3 {
        text-decoration: none;
    }
    .min20 {
        display: none;
    }
    .max19 {
        width: 90vw;
        margin-top: 75px;
        text-align: left;
    }
    
    .fourBusines {
        height: auto;
        text-align: center;
        &__buttonBack {
            display: none;
        }
        &__items {
            margin-top: -35px;
        }
        &__buttonBackM {
            color: white;
            width: 50px;
            height: 50px;
            background-color: #ff3d00;
            position: absolute;
            top: 0.5vh;
            left: 1vw;
            border-radius: 40px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
        &__header {
            font-size: 130%;
            padding-top: 2.5vh;
            padding-left: 15vw;
            margin-bottom: 2vh;
            font-weight: 500;
            color: aliceblue;
            animation: blinker 1s linear infinite;
            font-family: 'Noto Serif Regular';
        }
        &__phonenumber {
            font-size: 110%;
            color: aliceblue;
            animation: blinker 1s linear infinite;
            position: absolute;
            top: 6.5vh;
            left: 35vw;
        }
        &__WhatsApp2 {
            display: none;
        }
        @keyframes blinker {
            0% {
                color: blue;
            }
            33% {
                color: red;
            }
            33% {
                color: white;
            }
            100% {
                color: blue;
            }
        }
        &-boxitem {
            width: 90vw;
            height: 210vh;
            margin-top: 5vh;
            text-align: center;
            &-mainblock {
                display: block;
                position: relative;
                align-items: center;    
            }
            &__advantages {
                font-size: 16px;
                font-weight: 500;
                &_mod {
                    width: 80vw;
                    height: 28vh;
                    font-size: 14px;
                }
            }
            &__WhatsApp2 {
                display: block;
                position: fixed;
                bottom: 16vh;
                left: 9vw;
            }
            &__sendtomail {
                height:60px;
                width:60px;        
                position: fixed;
                left: 70vw;
                border-radius:50%;
                bottom: 1.5vh;
                right: 32vw;
                box-sizing: border-box;
                background-image: url(../../../public/avatar/мыло.png);
                background-size: cover;
            }
        }
        &__sendtomail {
            display: none;
        }
    }
}