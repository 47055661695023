@media screen and (min-width: 1024px) and (min-height: 1365px) {
    .wrapper, .whatsappWeb {
        display: none;
    }
    .whatsappMobile {
        display: block;
    }
    .wrapperM {
        display: block;
        width: 100vw;
        height: auto;   
        font-family: 'Noto Serif Regular';
        background: rgb(200, 242, 253);
        text-align: center;
    }
    .headerM {
        height: 20vh;
        background:url(../img/f2.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        &__phonenumber {
            font-size: 20px;
            font-weight: 500;
            color:#25D366;
            position: absolute;
            top: 1vh;
            left: 75vw;
        }
        &__whatsApp {
            position: absolute;
            top: 0vh;
            left: 2vw;
        }
        &__title {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            top: 4vh;
            font-size: 250%;
            font-style: normal;
            font-weight: 500;
            color: red;
            line-height: 20px;
            animation: blinker 1s linear infinite;
            text-shadow: 0px 3px 0px #b2a98f,
                    0px 14px 10px rgba(0,0,0,0.15),
                    0px 24px 2px rgba(0,0,0,0.1),
                    0px 34px 30px rgba(0,0,0,0.1);
            }
        @keyframes blinker {
            0% {
                color: orange;
            }
            33% {
                color: red;
            }
            66% {
                color: rgb(255, 0, 140);
            }
            100% {
                color: orange;
            }
        }
        &__buttons {
            width: 100vw;
            box-sizing: border-box;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
            top: 10vh;
            &_size {
                text-decoration: none;
                display: inline-block;
                width: 20vw;
                height: 4vh;
                line-height: 15px;
                border: none;
                border-radius: 15px;
                margin: 10px 10px;
                font-family: 'Noto Serif Regular';
                font-size: 12px;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 1px;
                font-weight: 900;
                color: #000000;
                background: #FF8000;
                box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
                transition: .3s;
                opacity: 0.8;
            }
            &_size:hover {
                background: #2EE59D;
                box-shadow: 0 15px 20px rgba(46, 229, 157, 0.4);
                color: white;
                transform: translateY(-7px);
            }
        }
    }
    .advantagesM {
        height: auto;
        top: 1vh;
        &__box {
            width: 90%;
            font-family: 'Noto Serif Regular';
            background: rgb(229, 241, 248);
            opacity: 0.9;
            border-radius: 30px;
            padding: 0.7%;
            box-sizing: border-box;
            margin-top: 1.5vh;
            margin-left: 5%;
            }
        &__box:hover{
            background: radial-gradient(ellipse at bottom, #87e0fd 0%,#ffffff 55%);
            opacity: 2;
            }    
        & h2 {
            font-size: 18px;
            font-weight: 900;
            line-height: 15px;
            color: red;
        }
        & p {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500;
            line-height: 15px;
            line-height: 25px;
        }
    }
    .title {
        margin-top: 2vh;
        margin-bottom: 2vh;
        font-size: 20px;
    }
    .help {
        width: 90%;
        display: block;
        margin-bottom: 20px;
        font-size: 14px;
        margin-left: 5%;
        &__item {
            border-radius: 20px;
            padding: 10px;
            line-height: 14px;
            &_box {
                display: flex;
                justify-content: space-between;
            }
            &_img1 {
                width: 170px;
                height: 100px;
                margin-left: 2px;
                margin-top: 15px;
            }
            &_img5 {
                width: 150px;
                height: 95px;
                background-size: cover;
                margin-top: 5px;
                margin-left: 4px;
                background-image: url(../img/экспертиза.jpg);
            }
            &_img6 {
                width: 200px;
                height: 100px;
                background-size: cover;
                margin-top: 5px;
                margin-left: 3px;
                background-image: url(../img/регистрация.jpg);
            }
        }
        & span {
            font-size: 20px;
            font-weight: 900;
            counter-reset: red;
        }
        & li {
            margin-top: 5px;
            font-weight: 500;
        }
    }
    .form {
        width: 80vw;
        padding: 5%;
        &__error {
            display: none;
            color: red;
            font-size: 14px;
            font-weight: 900;
        }
        &-header {
            font-size: 15px;
            text-align: center;
            margin-bottom: 15px;
            &__info {
                text-align: center;
                font-size: 100%;
                line-height: 18px;
                color:blue;
            }
        }
    & h3 {
        font-size: 22px;
        }
    &__sendButton {
        margin-top: 2vh;
        margin-left: 15vw;
        }
    &__input {
        font-size: 130%;
        width: 70vw;
        }
    &__mesage {
        font-size: 20px;
        width: 70vw;
        height: 15vh;
        }    
    }
    .check19-10 {
        display: block;
    }

    .footerM {
        width: 100vw;
        height: 15vh;
        box-sizing: border-box;
        background-color: #000080;
        color: #ffff;
        margin-top: 40px;
        padding: 15px;
        line-height: 20px;
        font-size: 16px;
        margin-bottom: 35px;
        .footerM-box {
            width: 90vw;
            margin-top: 15px;
            display: flex;
            justify-content: space-around;
            &-towBlock {
                line-height: 24px;
                &__contacts {
                    color: white;
                    text-decoration: none;
                    animation: blinker 1s linear infinite;
                    &_number {
                        margin-left: 1.8vw;
                    }
                }
            }
        }
        @keyframes blinker {
            0% {
                color: orange;
            }
            33% {
                color: red;
            }
            66% {
                color: white;
            }
            100% {
                color: orange;
            }
        }
        &-links {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            &__link {
                text-decoration: none;
                color: white;
                animation: blinker 1s linear infinite;
            }        
        }
    }
    .lastButton {
        &__buttonAp {
            color: white;
            width: 15vw;
            height: 4vh;
            margin: auto;
            padding-left: 20px;
            font-size: 20px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
        &__buttonAp:hover {
            background-color: #00bcd4;
        }
    }
    .top3 {
        text-decoration: none;
    }
    .min20 {
        display: none;
    }
    .max19 {
        width: 90vw;
        margin-top: 75px;
        text-align: left;
    }
    //--------------------------------------------------------------------------------------------------------------------------------

    .fourBusines {
        height: auto;
        text-align: center;
        &__buttonBack {
            display: none;
        }
        &__buttonBackM {
            color: white;
            width: 50px;
            height: 50px;
            background-color: #ff3d00;
            position: absolute;
            top: 0.5vh;
            left: 1vw;
            border-radius: 50px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
        &__header {
            font-size: 160%;
            padding-top: 3vh;
            padding-left: 0px;
            margin-left: -36vw;
            font-weight: 500;
            color: aliceblue;
            animation: blinker 1s linear infinite;
            font-family: 'Noto Serif Regular';
            margin-bottom: -5vh;
        }
        &__phonenumber {
            font-size: 140%;
            color: aliceblue;
            animation: blinker 1s linear infinite;
            position: absolute;
            top: 2.5vh;
            left: 53vw;
        }
        &__WhatsApp2 {
            display: none;
        }
        @keyframes blinker {
            0% {
                color: blue;
            }
            33% {
                color: red;
            }
            33% {
                color: white;
            }
            100% {
                color: blue;
            }
        }
        &-boxitem {
            width: 90vw;
            height: 50vh;
            margin-top: 2vh;
            &__advantages {
                font-size: 18px;
                &_mod {
                    width: 27vw;
                    height: 15vh;
                    box-sizing: border-box;
                    border: 1px solid #5111f1;
                    color: #5111f1;
                    font-size: 16px;
                    font-weight: 900;
                    line-height: 18px;
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        color: black;
                        line-height: 16px;
                    }
                }
            }
            &__WhatsApp2 {
                display: block;
                position: fixed;
                bottom: 15vh;
                left: 9vw;
            }
            &__sendtomail {
                height:80px;
                width:80px;        
                position: fixed;
                left: 70vw;
                border-radius:50%;
                bottom: 10vh;
                right: 32vw;
                box-sizing: border-box;
                background-image: url(../../../public/avatar/мыло.png);
                background-size: cover;
            }
        }
        &__sendtomail {
            display: none;
        }
    }
    }
