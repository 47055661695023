.form {
    width: 30vw;
    box-sizing: border-box;
    background: rgb(247, 242, 178);
    border-radius: 20px;
    margin: auto;
    padding: 3%;
    &__error {
        display: none;
        color: red;
        font-size: 14px;
        font-weight: 900;
    }
    &-header {
        text-align: center;
        margin-bottom: 15px;
        &__info {
            text-align: center;
            font-size: 90%;
            line-height: 18px;
            color:blue;
        }
    }
& h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;
    }
&__input {
    display: block;
    font-size: 120%;
    width: 25vw;
    height: 5vh;
    margin-top: 3vh;
    border-radius: 10px;
    background: rgb(220, 225, 247);
    }
&__sendButton {
    margin-top: 2vh;
    margin-left: 5vw;
    }
&__mesage {
    display: block;
    font-size: 140%;
    width: 25vw;
    height: 25vh;
    margin-top: 3vh;
    border-radius: 10px;
    background: rgb(220, 225, 247);
    }    
}
.check19-10 {
    display: none;
}