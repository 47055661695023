.title {
    text-align: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-size: 30px;
    font-weight: 700;
}
.helpM {
    display: none;
}
.help {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    &__item {
        height: auto;
        background-color: #ffff;
        border-radius: 30px;
        margin-right: 2vh;
        margin-bottom: 2vh;
        padding: 25px;
        line-height: 18px;
        &_box {
            display: flex;
            text-align: left;
        }
        &_img1 {
            width: 150px;
            height: 150px;
            margin-left: 25px;
            background-image: url(../img/ручка.jpg);
            background-size: cover;
        }
        &_img2 {
            width: 150px;
            height: 150px;
            margin-left: 25px;
            background-image: url(../img/весы.png);
            background-size: cover;
        }
        &_img3 {
            width: 200px;
            height: 150px;
            background-size: cover;
            margin-left: 10px;
            background-image: url(../img/молот.jpg);
        }
        &_img4 {
            width: 200px;
            height: 150px;
            background-size: cover;
            margin-left: 10px;
            background-image: url(../img/книга.png);
        }
    }        
    &__item:hover{
        color: blue;
        border: 2px solid red;
    }
    &__itemshot {
        height: 15vh;
        background-color: #ffff;
        border-radius: 30px;
        margin-right: 2vh;
        margin-bottom: 2vh;
        padding: 25px;
        line-height: 18px;
        &_box {
            display: flex;
            text-align: left;
        }
        &_img5 {
            width: 200px;
            height: 150px;
            background-size: cover;
            margin-left: 10px;
            background-image: url(../img/экспертиза.jpg);
        }
        &_img6 {
            width: 300px;
            height: 100px;
            background-size: cover;
            margin-left: 70px;
            background-image: url(../img/регистрация.jpg);
        }
    }
    &__itemshot:hover{
        color: blue;
        border: 2px solid red;
    }
    & span {
        font-size: 20px;
        font-weight: 700;
    }
    & li {
        list-style-type:decimal;
        list-style-position:inside;
        margin-top: 15px;
        font-style: unit($number: 1);
        font-weight: 600;
    }
}