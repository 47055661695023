.contactWrapper {
    width: 100vw;
    height: 160vh;
    background-color: black;

}
.yandexstar {
    width: 150;
    height: 50;
    position: absolute;
    bottom: 31vh;
    right: 3.8vw;
}
.ContactsHeader {
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 2px;
    padding-top: 25px;
}
.ContactsMain {
    margin-top: 7vh;
    display: flex;
    justify-content: space-around;
    &-table {
        color: white;
        font-size: 20px;
        &__line {
            padding: 10px;
            &_phonenumber {
                text-decoration: none;
                color: white;
            }
        }
    }
    &__namemap {
        color: white;
        text-align: center;
        font-size: 22px;
        margin-bottom: 15px;
    }
    &__WhatsApp2 {
        position: absolute;
        right: 5vw;
        bottom: 97vh;
    }
    &__sendtomail {
        height:78px;
        width:78px;        
        border-radius:50%;
        position: absolute;
        bottom: 2vh;
        right: 2vw;
        box-sizing: border-box;
        background-image: url(../../../public/avatar/мыло.png);
        background-size: cover;
    }
}
.formContact {
    width: 30vw;
    box-sizing: border-box;
    background: #98FB98;
    border-radius: 20px;
    padding: 3%;
    margin-top: 25px;
    margin-left: 35px;
    &__error {
        display: none;
        color: red;
        font-size: 14px;
        font-weight: 900;
    }
    &-header {
        text-align: center;
        margin-bottom: 15px;
        &__info {
            text-align: center;
            font-size: 90%;
            line-height: 18px;
            color:blue;
        }
    }
& h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;
    }
&__input {
    display: block;
    font-size: 120%;
    width: 25vw;
    height: 5vh;
    margin-top: 3vh;
    border-radius: 10px;
    background: rgb(220, 225, 247);
    }
&__sendButton {
    margin-top: 2vh;
    margin-left: 5vw;
    }
&__mesage {
    display: block;
    font-size: 140%;
    width: 25vw;
    height: 25vh;
    margin-top: 3vh;
    border-radius: 10px;
    background: rgb(220, 225, 247);
    }    
}
.check19-10 {
    display: none;
}

.feedbackbox {
    display: flex;
    justify-content: space-around;
    &-feedback {
        box-sizing: border-box;
        width: 30vw;
        height: auto;
        background-color: #98FB98;
        border-radius: 20px;
        padding: 40px;
        margin-right: 120px;
        margin-top: 25px;
        &__inputs {
            display: block;
            text-align: center;
            &_input {
                display: block;
                font-size: 100%;
                width: 10vw;
                height: 4vh;
                margin-top: 20px;
                border-radius: 10px;
                background: rgb(220, 225, 247);
            }
            &_mesage {
                display: block;
                font-size: 140%;
                width: 25vw;
                height: 10vh;
                margin-top: 3vh;
                border-radius: 10px;
                background: rgb(220, 225, 247);
            }
            &_button {
                height: 40px;
                width: 8vw;
                font-size: 14px;
                margin-top: 20px;
                border-radius: 10px;
                font-family: roboto;
                font-weight: 500;
                letter-spacing: 0.5px;
                background-color: #FF8000;
                color: black;
                border: none;
            }
            &_button:hover {
                background-color: #2EE59D;
                color: white;
                box-shadow: 0 15px 20px rgb(46, 229, 157, 0.4);
            }
        }

    }
}