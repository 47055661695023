@media screen and (max-width: 1919px) {
    .advantages {
        &__box {
            width: 13%;
            margin-right: 4%;
            box-sizing: content-box;
        }
    }
    .fourBusines {
        &__phonenumber {
        top: 4vh;
        left: 44vw;
        }
    }
}

//*************************************************************************************

@media screen and (max-width: 1681px) {
    .help {
        margin-bottom: 20px;
        &__itemshot {
            height: auto;
            width: 40vw;
            border-radius: 30px;
            margin-right: 2vh;
            margin-bottom: 2vh;
            padding: 25px;
            line-height: 18px;
        }
    }
    .adaptationHelp {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer {
        &__buttonAp {
            width: 11vw;
            font-size: 19px;
        }
    }
    .fourBusines {
        &__phonenumber {
        top: 4.7vh;
        left: 45vw;
        }
        &__buttonBack {
            width: 7vw;
            height: 4vh;
            text-align: center;
        }
        &-boxitem {
            &__advantages {
                &_mod {
                    span {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 16px;
                    }
                } 
            }
        }
        &__WhatsApp2 {
            position: absolute;
            right: 7vw;
            bottom: 13.5vh;
        }
        &__sendtomail {
            right: 27vw;
            bottom: 6vh;
        }
    }
}

//*******************************************************************************

@media screen and (max-width: 1601px) {
    .min20 {
        display: none;
    }
    .max19 {
        display: block;
        width: 860px;
    }
    .fourBusines {
        &__items {
            width: 53vw;
        }
        &__header {
            font-size: 30px;
            padding-left: 12vw;
        }
        &__phonenumber {
            top: 4vh;
            left: 35vw;
        }
        &__buttonBack {
            width: 7vw;
            height: 4vh;
            text-align: center;
        }
        &-boxitem {
            width: 40vw;
            margin-top: 7vh;
            &__advantages {
                &_mod {
                    padding: 0.5em;
                    span {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 15px;
                        margin-top: 0.3vh;
                    }
                } 
            }
        }
    }
}

//*******************************************************************************

@media screen and (max-width: 1599px) {
    .min20 {
        display: none;
    }
    .max19 {
        display: block;
        width: 770px;
    }
    .fourBusines {
        &__items {
            width: 53vw;
        }
        &__header {
            font-size: 30px;
            padding-left: 12vw;
        }
        &__phonenumber {
            top: 4vh;
            left: 38vw;
            }
        &__buttonBack {
            width: 7vw;
            height: 4vh;
            text-align: center;
        }
        &-boxitem {
            width: 40vw;
            margin-top: 7vh;
            &__advantages {
                &_mod {
                    padding: 0.5em;
                    span {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 15px;
                        margin-top: 0.3vh;
                    }
                } 
            }
        }
        &__sendtomail {
            right: 37vw;
            bottom: 10vh;
        }
        &__WhatsApp2 {
            right: 9vw;
            bottom: 17.5vh;
        }
    }
    .footer {
        padding: 1px;
        &__buttonAp {
            width: 10.5vw;
            height: 5.5vh;
            padding-left: 16px;
            font-size: 16px;
        }
        &-box {
            &__contacts {
                &_number {
                    margin-left: 2.5vw;
                }
            }
        }
        &__ava {
            width: 26vw;
        }
    }
    .form {
        &-header {
            &__info {
                text-align: center;
                font-size: 80%;
                line-height: 17px;
                color:blue;
            }
        }
    }
    .check24-20 {
        display: none;
    }
    .check19-10 {
        display: block;
    }
}

//*******************************************************************************

@media screen and (max-width: 1439px) {
    .header{
        &__phonenumber {
            font-size: 170%;
        }
        &__title {
            font-size: 300%;
        }
        &__buttons {
            top: 150px;
            &_size {
                width: 240px;
                height: 45px;
                }
            }
        }
    .advantages {
        top: 35%;
    &__box {
        width: 14%;
        height: 40vh;
        margin-right: 2%;
        }
    & h2 {
        font-size: 17px;
        line-height: 25px;
        }
    & p {
        margin-top: 15px;
        font-size: 17px;
        font-weight: 500;
        line-height: 20px;
        }
    }
    .form {
        width: 36vw;
        padding: 3%;
    &__input {
        font-size: 100%;
        width: 50vh;
        }
    }
    .footer {
        height: 17vh;
        padding: 5px;
        line-height: 30px;
        font-size: 15px;
        &-box {
            line-height: 30px;
        }
        &__buttonAp {
            display: none;
        }
        &__buttonAp15 {
            color: white;
            display: block;
            width: 10vw;
            height: 5.5vh;
            padding-left: 20px;
            font-size: 16px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
        &__buttonAp15:hover {
            background-color: #00bcd4;
        }
        &__ava {
            width: 28vw;
            display: flex;
            align-items: center;
            justify-content:center;
        }
    }
    .title {
        margin-top: 5vh;
        margin-bottom: 5vh;
        font-size: 30px;
        font-weight: 700;
    }
    .help {
        &__item {
            padding: 15px;
            line-height: 16px;
        }        
        &__itemshot {
            height: 21vh;
            padding: 15px;
            line-height: 16px;
            &_img6 {
                margin-left: 10px;
            }
        }
        & span {
            font-size: 16px;
            font-weight: 700;
        }
        & li {
            margin-top: 5px;
            font-weight: 400;
        }
    }
    .fourBusines {
        &__buttonBack {
            width: 8vw;
        }
        &__header {
            font-size: 30px;
            padding-left: 14vw;
            font-weight: 900;
            color: #d9d6f5;
            animation: none;
        }
        &__phonenumber {
            font-size: 3vh;
            color: #0b000e;
            animation: none;
            display: block;
            padding-left: 3vw;
            padding-top: 0.7vh;
        }
        &__WhatsApp2 {
            right: 9vw;
            bottom: 15vh;
        }
        &__items {
            width: 55vw;
        }
        &-boxitem {
            width: 40vw;
            height: 78vh;
            margin-left: 2vw;
            margin-top: 5vh;
            &__advantages {
                font-size: 20px;
                &_mod {
                    width: 19vw;
                    height: 22vh;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 0.5em auto;
                    span {
                        margin-top: 1vh;
                        font-size: 16px;
                        font-weight: 600;
                    }
                } 
            }
        }
        &__sendtomail {
            height:78px;
            width:78px;        
            border-radius:50%;
            position: absolute;
            bottom: 5vh;
            right: 32vw;
        }
    }
}
//**************************************************************************************************************************

@media screen and (min-width: 1365px) and (min-height: 1020px){
    .wrapper{
        width: 1366px;
    }
    .header{
        background:url(../img/f2.jpg);
        background-size: cover;
        height: 50vh;
        &__phonenumber {
            font-size: 170%;
        }
        &__title {
            font-size: 250%;
            top: 2vh
        }
        &__buttons {
            top: 80px;
            &_size {
                width: 240px;
                height: 55px;
                }
            }
        }
    .advantages {
        top: 17vh;
    &__box {
        width: 14%;
        height: 27vh;
        margin-right: 2%;
        }
    }
    .form {
        width: 42vw;
    &__input {
        font-size: 100%;
        width: 35vw;
        }
    &__mesage {
        width: 35vw;
        height: 25vh;
        } 
    &__sendButton {
        margin-left: 8vw;
        }
    }
    .footer {
        height: 17vh;
        padding: 5px;
        line-height: 30px;
        font-size: 15px;
        &-box {
            line-height: 30px;
        }
        &__buttonAp {
            display: none;
        }
        &__buttonAp15 {
            color: white;
            display: block;
            width: 10vw;
            height: 5.5vh;
            padding-left: 20px;
            font-size: 16px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
        &__buttonAp15:hover {
            background-color: #00bcd4;
        }
        &__ava {
            width: 28vw;
            display: flex;
            align-items: center;
            justify-content:center;
        }
    }
    .title {
        margin-top: 5vh;
        margin-bottom: 5vh;
        font-size: 30px;
        font-weight: 700;
    }
    .help {
        &__item {
            padding: 15px;
            line-height: 16px;
        }        
        &__itemshot {
            height: 21vh;
            padding: 15px;
            line-height: 16px;
            &_img6 {
                margin-left: 10px;
            }
        }
        & span {
            font-size: 16px;
            font-weight: 700;
        }
        & li {
            margin-top: 5px;
            font-weight: 400;
        }
    }
    .fourBusines {
        height: 1024px;
        &__buttonBack {
            width: 8vw;
        }
        &__header {
            font-size: 30px;
            padding-left: 14vw;
            font-weight: 900;
            color: #d9d6f5;
            animation: none;
        }
        &__phonenumber {
            font-size: 130%;
        }
        &__WhatsApp2 {
            right: 35vw;
            top: 64vh;
        }
        &-boxitem {
            width: 40vw;
            height: 50vh;
            margin-left: 2vw;
            margin-top: 10vh;
            &__advantages {
                font-size: 20px;
                &_mod {
                    width: 19vw;
                    height: 14vh;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 0.5em auto;
                } 
            }
        }
        &__sendtomail {
            top: 65vh;
            right: 5vw;
        }
    }
}


//************************************************************************************************************

@media screen and (max-width: 1365px) {
    .header {
        &__whatsApp {
            position: absolute;
            top: 1vh;
            left: 0.5vw;
        }
    }
    .whatsapp-button {
        height: 64px;
        width: 64px;
    }
    .whatsapp-button .text-button {
        height:64px;
        width:64px;        
   }
    .advantages {
        top: 32%;
        &__box {
            height: auto;
        }
    }
    .footer {
        &__ava {
            width: 25vw;
        }
        &__buttonAp15 {
            display: none;
        }
        &__buttonAp13 {
            color: white;
            display: block;
            width: 10vw;
            height: 5.5vh;
            padding-left: 15px;
            font-size: 16px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
        &__buttonAp13:hover {
            background-color: #00bcd4;
        }
    }
    .fourBusines {
        width: 100vw;
        height: 150vh;
        background-image: url(../img/длябизнеса.jpeg);
        display: block;
        background-position: right 15%;
        background-size: cover;
        background-repeat: no-repeat;
        &__header {
            font-size: 30px;
            padding-top: 4vh;
            padding-left: 25vw;
            color: #ff3d00;
        }
        &__phonenumber {
            font-size: 160%;
            position: absolute;
            top: 3.8vh;
            left: 53vw;
            color: #ff3d00;
        }
        &__WhatsApp2 {
            position: fixed;
            left: 90vw;
            top: 3vh;
        }
        &__sendtomail {
            position: fixed;
            left: 90vw;
            top: 20vh;
        }
        &-boxitem {
            width: 85vw;
            height: 55vh;
            &__advantages {
                font-family: 'Noto Serif Regular';
                font-size: 25px;
                font-weight: 900;
                color: black;
                padding-top: 10px;
                margin: auto;
                &_mod {
                    width: 26vw;
                    height: 18vh;
                    box-sizing: border-box;
                    border: 1px solid #5111f1;
                    color: #5111f1;
                    font-size: 17px;
                    line-height: 22px;
                    text-align: center;
                    font-weight: 700;
                    margin: 1em auto;
                    padding: 1em;
                    border-radius: 10px;
                    background: rgb(100,100,100) radial-gradient(circle at 0 0, rgba(255,255,255,.65), rgba(255,255,255,.35));
                    box-shadow:
                     inset rgba(0,0,0,.5) -3px -3px 8px,
                     inset rgba(255,255,255,.9) 3px 3px 8px,
                     rgba(0,0,0,.8) 3px 3px 8px -3px;
                    span {
                        display: block;
                        margin-top: 1vh;
                        font-size: 16px;
                        font-weight: 600;
                        color: green;
                    }
                }
            }
        }
    }
    .max19 {
        width: 85vw;
    }
}
//****************************************************************************************************************************************

@media screen and (max-width: 1279px) {
    .header {
        height: 65vh;
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &__title {
            top: 70px;
            left: 7%;
        }
        &__buttons {
            top: 135px;
            &_size {
                width: 200px;
                height: 45px;
                line-height: 10px;
                margin: 5px 10px;
                font-size: 14px;
                letter-spacing: 0.5px;
            }
        }
    }
    .advantages {
        height: auto;
        top: 210px;
    &__box {
        width: 15%;
        margin-right: 2%;
        background: rgb(229, 241, 248);
        opacity: 0.6;
        border-radius: 20px;
        padding: 1%;
        }
    & h2 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        }
    & p {
        margin-top: 10px;
        font-size: 15px;
        font-weight: 500;
        line-height: 17px;
        }
    }
    .form {
        width: 45vw;
        padding: 5vh;
    & h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 15px;
        }
    &__input {
        display: block;
        font-size: 100%;
        width: 50vh;
        height: 5vh;
        margin-top: 3vh;
        border-radius: 10px;
        background: rgb(220, 225, 247);
        }
    &__sendButton {
        margin-top: 2vh;
        margin-left: 5vw;
        }
    }
    .lastButton {
        display: block;
        height: 10vh;
    }
    .footer {
        padding: 1px;
        background-color: #000080;
        line-height: 15px;
        font-size: 14px;
        &-box {
            line-height: 25px;
            &__contacts {
                &_number {
                    margin-left: 3.5vw;
                }
            }
        }
        &__buttonAp13 {
            display: none;
        }
        &__buttonAp12 {
            display: block;
            color: white;
            width: 15vw;
            height: 6.5vh;
            padding-left: 20px;
            font-size: 20px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
            margin: 25px auto;
        }
    }
    .fourBusines {
        height: 160vh;
        &__buttonBack {
            width: 11vw;
            height: 5vh;
            top: 2vh;
            left: 1vw;
        }
        &__phonenumber {
            font-size: 3vh;
            top: 4vh;
            left: 58vw;
        }    
        &-boxitem {
        height: 58vh;
            &__advantages {
                font-family: 'Noto Serif Regular';
                font-size: 25px;
                font-weight: 900;
                color: black;
                padding-top: 7px;
                margin: auto;
                &_mod {
                    width: 26vw;
                    height: 22vh;
                    span {
                        display: block;
                        margin-top: 1vh;
                        font-size: 14px;
                        font-weight: 500;
                        color: green;
                    }
                }
            }
        }
    }
}
//**********************************************************************************************************************

@media screen and (max-height: 721px) {
    .header {
        height: 80vh;
    }
    .fourBusines {
        height: 170vh;
    }
}

//*********************************************************************************************************************

@media screen and (max-height: 601px) {
    .header {
        height: 82vh;
    }
    .help {
        &__itemshot {
            height: 25vh;
            padding: 15px;
            line-height: 16px;
        }
    }
    .form {
        width: 40vw;
        padding: 5vh;
    }
    .fourBusines {
        height: 200vh;
        &__buttonBack {
            height: 7vh;
        }
        &__phonenumber {
            font-size: 25px;
            top: 3.5vh;
        }    
        &-boxitem {
            height: 68vh;
            &__advantages {
                &_mod {
                    width: 26vw;
                    height: 25vh;
                }
            }
        }
    }
}