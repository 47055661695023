.wrapperM {
    display: none;
}
.wrapper{
    width: 100%;
    height: auto;
    font-family: 'Noto Serif Regular';
    background: rgb(200, 242, 253);
    box-sizing: border-box;
}
.header{
    height: 635px;
    position: relative;
    background:url(../img/f2.jpg);
    background-size: cover;
    text-align: center;
    &__phonenumber {
        font-size: 3vh;
        color:#25D366;
        position: absolute;
        top: 2vh;
        left: 75vw;
    }
    &__whatsApp {
        position: absolute;
        top: 1vh;
        right: 5vw;
    }
    &__title {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 90px;
        font-size: 350%;
        font-style: normal;
        font-weight: 700;
        color: rgb(4, 4, 66);
        line-height: 20px;
        animation: blinker 1s linear infinite;
        text-shadow: 0px 3px 0px #b2a98f,
             0px 14px 10px rgba(0,0,0,0.15),
             0px 24px 2px rgba(0,0,0,0.1),
             0px 34px 30px rgba(0,0,0,0.1);
        }
    @keyframes blinker {
        0% {
            color: orange;
        }
        33% {
            color: red;
        }
        66% {
            color: blue;
        }
        100% {
            color: orange;
        }
    }
    &__buttons {
        width: auto;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        top: 175px;
        &_size {
            text-decoration: none;
            display: inline-block;
            width: 260px;
            height: 55px;
            line-height: 15px;
            border: none;
            border-radius: 15px;
            margin: 10px 20px;
            font-family: 'Noto Serif Regular';
            font-size: 15px;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 3px;
            font-weight: 700;
            color: #000000;
            background: #FF8000;
            box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
            transition: .3s;
            }
        &_size:hover {
            background: #2EE59D;
            box-shadow: 0 15px 20px rgba(46, 229, 157, 0.4);
            color: white;
            transform: translateY(-7px);
            }
        }
    }

.advantages {
    height: auto;
    position: absolute;
    top: 280px;
    display: flex;
    align-content: space-between;
    justify-content: center;
&__box {
    width: 12%;
    font-family: 'Noto Serif Regular';
    text-align: center;
    margin-right: 5%;
    background: rgb(229, 241, 248);
    opacity: 0.6;
    border-radius: 30px;
    padding: 0.7%;
    box-sizing: border-box;
    }
&__box:hover{
    background: radial-gradient(ellipse at bottom, #87e0fd 0%,#ffffff 55%);
    opacity: 2;
    }
& h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: red;
    }
& p {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 700;
    color: black;
    line-height: 25px;
    }
}