@media screen and (min-height: 426px) and (max-height: 450px) {
    
    .wrapperM, .whatsappWeb, .help {
        display: none;
    }
    .wrapper {
        width: 100vw;
        display: block;
        box-sizing: border-box;
    }
    .whatsappMobile {
        display: block;
    }
    .header {
        height: 100vh;
        &__phonenumber {
            font-size: 110%;
            top: 4vh;
            left: 77vw;
        }
        &__whatsApp {
            top: -1.5vh;
            left: 1vw;
        }
        &__title {
            top: 5vh;
            left: -3vw;
            font-size: 230%;
            font-weight: 200;
        }
        &__buttons {
            top: 17vh;
            display: flex;
            justify-content: center;
            &_size {
                width: 15vw;
                height: 13vh;
                line-height: 20px;
                margin-left: 10px;
                font-size: 12px;
                letter-spacing: 0.3px;
            }
        }
    }
    .advantages {
        top: 40vh;
    &__box {
        width: 15%;
        margin-right: 2%;
        background: rgb(229, 241, 248);
        opacity: 0.6;
        border-radius: 20px;
        padding: 1%;
        }
    & h2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 5vh;
        }
    & p {
        word-break: break-all;
        margin-top: 1vh;
        font-size: 85%;
        line-height: 4vh;
        }
    }
    .title {
        margin-top: 4vh;
        margin-bottom: 4vh;
        font-size: 120%;
    }
    .helpM {
        width: 100vw;
        box-sizing: border-box;
        display: block;
        margin-bottom: 4vh;
        padding-left: 10vw; 
        &__item {
            background-color: #ffff;
            width: 75vw;
            border-radius: 20px;
            margin-bottom: 2vh;
            padding: 15px;
            line-height: 4vh;
            &_box {
                display: flex;
                justify-content: left;
                font-size: 80%;
            }
            &_img1 {
                width: 110px;
                height: 110px;
                margin-left: 1vw;
                background-image: url(../img/ручка.jpg);
                background-size: cover;
            }
            &_img2 {
                width: 110px;
                height: 110px;
                margin-left: 1vw;
                background-image: url(../img/весы.png);
                background-size: cover;
            }
            &_img3 {
                width: 110px;
                height: 75px;
                background-size: cover;
                margin-left: -4vw;
                background-image: url(../img/молот.jpg);
            }
            &_img4 {
                width: 200px;
                height: 150px;
                background-size: cover;
                margin-left: 10px;
                background-image: url(../img/книга.png);
            }
            &_img5 {
                width: 100px;
                height: 75px;
                background-size: cover;
                margin-left: 10px;
                background-image: url(../img/экспертиза.jpg);
            }
            &_img6 {
                width: 300px;
                height: 100px;
                background-size: cover;
                margin-left: 70px;
                background-image: url(../img/регистрация.jpg);
            }
        }        
        &__item:hover{
            color: blue;
            border: 2px solid red;
        }
        & span {
            font-size: 120%;
        }
        & li {
            width: 50vw;
            margin-top: 1vh;
            font-weight: 500;
            list-style-type:decimal;
            list-style-position:inside;
            font-style: unit($number: 1);
        }
    }

    .form {
        width: 45vw;
        padding: 5vh;
    & h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 15px;
        }
    &__sendButton {
        margin-top: 2vh;
        margin-left: 7vw;
        }
    &__input {
        display: block;
        font-size: 100%;
        width: 40vw;
        height: 12vh;
        }
    &__mesage {
        display: block;
        font-size: 140%;
        width: 40vw;
        height: 35vh;
        }
    }  
    .lastButton {
        display: block;
        height: 30vh;
    }
    .footer {
        width: 100vw;
        height: 40vh;
        box-sizing: border-box;
        padding: 1px;
        background-color: #000080;
        line-height: 20px;
        font-size: 14px;
        justify-content: space-between;
        &-box {
            line-height: 37px;
            &__contacts {
                &_number {
                    margin-left: 4.5vw;
                }
            }
        }
        &__ava {
            width: 40vw;
            justify-content: space-between;
        }
        &__links {
            display: none;
        }
        &__buttonAp13 {
            display: none;
        }
        &__buttonAp12 {
            display: block;
            color: white;
            width: 17vw;
            height: 15vh;
            padding-left: 20px;
            font-size: 20px;
            background-color: #ff3d00;
            border-radius: 20px;
            display: flex;
            align-items: center;
            border: 1px solid rgb(100,100,100);
            box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
        }
    }
    .fourBusines {
        height: auto;
        &__buttonBack {
            width: 12vw;
            height: 10vh;
        }
        &__header {
            font-size: 25px;
            padding-left: 25vw;
            font-weight: 500;
            color: black;
            animation: blinker 1s linear infinite;
            margin-bottom: 35px;
        }
        &__phonenumber {
            font-size: 110%;
            top: 5vh;
            left: 60vw;
            font-weight: 500;
            color: black;
            animation: blinker 1s linear infinite;
        }
        @keyframes blinker {
            0% {
                color: orange;
            }
            33% {
                color: red;
            }
            66% {
                color: rgb(7, 0, 15);
            }
            100% {
                color: orange;
            }
        }
        &__WhatsApp2 {
            left: 88vw;
            top: 14vh;
        }
        &__sendtomail {
            height:65px;
            width:65px;        
            position: fixed;
            top: 50vh;
            left: 88.5vw;
        }
        &-boxitem {
        height: 120vh;
            &__advantages {
                font-family: 'Noto Serif Regular';
                font-size: 25px;
                font-weight: 900;
                color: black;
                padding-top: 7px;
                margin: auto;
                &_mod {
                    width: 26vw;
                    height: 45vh;
                    span {
                        display: block;
                        margin-top: 1vh;
                        font-size: 14px;
                        font-weight: 500;
                        color: black;
                    }
                }
            }
        }
    }
}