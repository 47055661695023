.fourBusines__buttonBackM, .fourBusines-boxitem__WhatsApp2, .fourBusines-contaktForMobile, .max19 {
    display: none;
}
 
.fourBusines {
    width: 100vw;
    height: 100vh;
    background-image: url(../img/длябизнеса.jpeg);
    background-size: cover;
    font-family: 'Noto Serif Regular';
    display: flex;
    font-family: 'Noto Serif Regular';
    &__buttonBack {
        color: white;
        width: 6vw;
        background-color: #ff3d00;
        position: absolute;
        top: 2vh;
        left: 1vw;
        border-radius: 20px;
        display: flex;
        align-items: center;
        border: 1px solid rgb(100,100,100);
        box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
    }
    &__buttonBack:hover {
        background-color: #00bcd4;
    }
    &__header {
        font-size: 35px;
        padding-top: 4vh;
        padding-left: 15vw;
        margin-bottom: 3vh;
        font-weight: 900;
        color: aliceblue;
        animation: blinker 1s linear infinite;
        font-family: 'Noto Serif Regular';
    }
    &__phonenumber {
        font-size: 3vh;
        color: aliceblue;
        animation: blinker 1s linear infinite;
        position: absolute;
        top: 4.5vh;
        left: 40vw;
    }
    &__WhatsApp2 {
        position: absolute;
        right: 5vw;
        bottom: 11vh;
    }
    @keyframes blinker {
        0% {
            color: orange;
        }
        33% {
            color: red;
        }
        66% {
            color: white;
        }
        100% {
            color: orange;
        }
    }
    &__items {
        width: 60vw;
        padding-left: 2vw;
        opacity: 0.8;
    }
    &-boxitem {
        width: 35vw;
        height: 70vh;
        margin-left: 2vw;
        margin-top: 11vh;
        box-sizing: border-box;
        background-color:azure;
        opacity: 0.7;
        text-align: center;
        &-mainblock {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;    
        }
        &__advantages {
            font-family: 'Noto Serif Regular';
            font-size: 25px;
            font-weight: 900;
            color: black;
            padding-top: 10px;
            &_mod {
                width: 16vw;
                height: 18vh;
                box-sizing: border-box;
                border: 1px solid #5111f1;
                color: #5111f1;
                font-size: 17px;
                line-height: 22px;
                text-align: center;
                font-weight: 700;
                margin: 1em auto;
                padding: 1em;
                border-radius: 10px;
                background: rgb(100,100,100) radial-gradient(circle at 0 0, rgba(255,255,255,.65), rgba(255,255,255,.35));
                box-shadow:
                 inset rgba(0,0,0,.5) -3px -3px 8px,
                 inset rgba(255,255,255,.9) 3px 3px 8px,
                 rgba(0,0,0,.8) 3px 3px 8px -3px;
                span {
                    display: block;
                    margin-top: 1vh;
                    font-size: 16px;
                    font-weight: 600;
                    color: green;
                }
            }
        }
    }
    &__sendtomail {
        height:78px;
        width:78px;        
        border-radius:50%;
        position: absolute;
        bottom: 5vh;
        right: 32vw;
        box-sizing: border-box;
        background-image: url(../../../public/avatar/мыло.png);
        background-size: cover;
    }
}