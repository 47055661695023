.footer {
    width: 100%;
    height: 12vh;
    margin-top: 50px;
    padding: 15px;
    box-sizing: border-box;
    background-color: #000080;
    color: #ffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 20px;
    &-box {
        position: relative;
        line-height: 25px;
            &__contacts {
            color: white;
            text-decoration: none;
            animation: blinker 1s linear infinite;
                &_number {
                    margin-left: 1.8vw;
                }
            }
    }
    @keyframes blinker {
        0% {
            color: orange;
        }
        33% {
            color: red;
        }
        66% {
            color: white;
        }
        100% {
            color: orange;
        }
    }
    &__ava {
        width: 26vw;
        display: flex;
        align-items: center;
        justify-content:center;
    }
    &__buttonAp {
        color: white;
        width: 9vw;
        height: 5.5vh;
        padding-left: 20px;
        font-size: 20px;
        background-color: #ff3d00;
        border-radius: 20px;
        display: flex;
        align-items: center;
        border: 1px solid rgb(100,100,100);
        box-shadow: 20px 0 20px -20px #000 inset, -20px 0 20px -20px #000 inset;
    }
    &__buttonAp:hover {
        background-color: #00bcd4;
    }
    &__links {
        text-decoration: none;
        color: white;
        animation: blinker 1s linear infinite;
    }
    &__buttonAp15 {
        display: none;
    }
    &__buttonAp13 {
        display: none;
    }
    &__buttonAp12 {
        display: none;
    }
}
.lastButton {
    display: none;
}

.top3 {
    text-decoration: none;
}